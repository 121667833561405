<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <label
                    :class="{'text-danger': states.owner_type === false}"
                    class="col-form-label col-lg-2"
                    for="owner_type"
                >{{ $t('dynamic_fields::fields.columns.owner_type').ucFirst() }}</label>
                <div :key="ownerType" class="col-lg-10">
                    <template v-if="!model.id">
                        <select
                            id="owner_type"
                            v-model="model.owner_type"
                            :class="{'border-danger': states.owner_type === false}"
                            class="form-select"
                            name="owner_type"
                        >
                            <option :value="model.id ? null : undefined">
                                {{ $t('dynamic_fields::fields.placeholders.owner_type').ucFirst() }}
                            </option>
                            <template v-for="(owner, index) in owners" :key="index">
                                <option v-if="ownerType ? ownerType === $tc(owner.translation_key, 2).normalize('NFD').replace(/\p{Diacritic}/gu, '') : true"
                                        :value="owner.model_name">{{ $t(owner.translation_key).ucFirst() }}
                                </option>
                            </template>
                        </select>
                        <div :class="{'d-block': states.owner_type === false}" class="invalid-feedback">
                            {{ errors.owner_type }}
                        </div>
                    </template>
                    <template v-else>
                        {{ owner }}
                    </template>
                </div>
            </div>
            <div class="row mb-4">
                <label
                    :class="{'text-danger': states.field_type_id === false}"
                    class="col-form-label col-lg-2"
                    for="field_type_id"
                >{{ $t('dynamic_fields::fields.columns.field_type_id').ucFirst() }}</label>
                <div class="col-lg-10">
                    <select
                        id="field_type_id"
                        v-model="model.field_type_id"
                        :class="{'border-danger': states.field_type_id === false}"
                        class="form-select"
                        name="field_type_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('dynamic_fields::fields.placeholders.field_type_id').ucFirst() }}
                        </option>
                        <option v-for="(type, index) in types" :key="index" :value="type.id">
                            {{ type.translation_key ? $t(type.translation_key).ucFirst() : type.name }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.field_type_id === false}" class="invalid-feedback">
                        {{ errors.field_type_id }}
                    </div>
                </div>
            </div>
            <template v-if="hasOptionList">
                <div class="row mb-4">
                    <label
                        class="col-form-label col-lg-2"
                    >{{ $t('dynamic_fields::fields.columns.select_options').ucFirst() }}</label>
                    <div class="col-lg-10">
                        <fieldset class="bg-light p-3 rounded">
                            <div class="row mb-4">
                                <label
                                    :class="{'text-danger': states.label === false}"
                                    class="col-form-label col-lg-2"
                                    for="label"
                                >{{ $t('dynamic_fields::fields.columns.label').ucFirst() }}</label>
                                <div class="col-lg-10">
                                    <input
                                        id="label"
                                        v-model="option.label"
                                        :class="{'border-danger': states.label === false}"
                                        :placeholder="$t('dynamic_fields::fields.placeholders.label').ucFirst()"
                                        class="form-control"
                                        name="label"
                                        type="text"
                                    />
                                    <div :class="{'d-block': states.label === false}" class="invalid-feedback">
                                        {{ errors.label }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label
                                    :class="{'text-danger': states.value === false}"
                                    class="col-form-label col-lg-2"
                                    for="value"
                                >{{ $t('dynamic_fields::fields.columns.value').ucFirst() }}</label>
                                <div class="col-lg-10">
                                    <input
                                        id="value"
                                        v-model="option.value"
                                        :class="{'border-danger': states.value === false}"
                                        :placeholder="$t('dynamic_fields::fields.placeholders.value').ucFirst()"
                                        class="form-control"
                                        name="value"
                                        type="text"
                                    />
                                    <div :class="{'d-block': states.value === false}" class="invalid-feedback">
                                        {{ errors.value }}
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <label
                                    class="col-form-label col-lg-2"
                                    for="option_translation_key"
                                >{{ $t('dynamic_fields::fields.columns.translation_key').ucFirst() }}</label>
                                <div class="col-lg-10">
                                    <translation-input
                                        id="option_translation_key"
                                        v-model="option.translation_key"
                                        :placeholder="$t('dynamic_fields::fields.placeholders.translation_key').ucFirst()"
                                    ></translation-input>
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col-lg-2"></div>
                                <div class="col-lg-10">
                                    <b-button v-if="!editOn" size="sm" variant="primary" @click="addToSelectOptions">
                                        {{ $t('base.add').ucFirst() }}
                                    </b-button>
                                    <b-button v-else size="sm" variant="primary" @click="saveSelectOption">
                                        {{ $t('base.save').ucFirst() }}
                                    </b-button>
                                </div>
                            </div>
                            <div v-if="selectOptions" class="row">
                                <div class="col-lg-2">{{ $t('base.preview').ucFirst() }}</div>
                                <div class="col-lg-10">
                                    <div v-if="inputType === 'select'" class="d-flex">
                                        <select
                                            id="select_options"
                                            v-model="selectedOption"
                                            class="form-select me-2"
                                            name="select_options"
                                        >
                                            <option :value="typeof selectedOption === 'undefined' ? undefined : null">{{
                                                    model.placeholder_translation_key ? $t(model.placeholder_translation_key).ucFirst() : model.placeholder
                                                }}
                                            </option>
                                            <option v-for="(option, index) in selectOptions" :key="index"
                                                    :value="option.value">{{
                                                    option.translation_key ? $t(option.translation_key).ucFirst() : option.label
                                                }}
                                            </option>
                                        </select>
                                        <b-button :title="$t('base.edit').ucFirst()" class="me-1" variant="primary"
                                                  @click="edit">
                                            <i class="fas fa-pencil-alt"></i>
                                        </b-button>
                                        <b-button :title="$t('base.delete').ucFirst()" variant="danger" @click="remove">
                                            <i class="fas fa-trash"></i>
                                        </b-button>
                                    </div>
                                    <div v-else-if="inputType === 'radio'">
                                        <div v-for="(option, index) in selectOptions"
                                             :key="index" class="form-check d-inline-block me-4">
                                            <label class="form-check-label mb-0" for="radio_1">{{
                                                    option.translation_key ? $t(option.translation_key).ucFirst() : option.label
                                                }}</label>
                                            <input
                                                :id="'radio_' + index"
                                                v-model="selectedOption"
                                                :value="option.value || option.label"
                                                class="form-check-input form-radio-dark"
                                                name="radio"
                                                type="radio"
                                            />
                                        </div>
                                        <b-button :title="$t('base.edit').ucFirst()" class="me-1" size="sm"
                                                  variant="primary" @click="edit">
                                            <i class="fas fa-pencil-alt"></i>
                                        </b-button>
                                        <b-button :title="$t('base.delete').ucFirst()" size="sm" variant="danger"
                                                  @click="remove">
                                            <i class="fas fa-trash"></i>
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </template>
            <div class="row mb-4">
                <label
                    :class="{'text-danger': states.name === false}"
                    class="col-form-label col-lg-2"
                    for="name"
                >{{ $t('dynamic_fields::fields.columns.name').ucFirst() }}</label>
                <div class="col-lg-10">
                    <input
                        id="name"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false}"
                        :placeholder="$t('dynamic_fields::fields.placeholders.name').ucFirst()"
                        class="form-control"
                        name="name"
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>
            </div>
            <div class="row mb-4">
                <label
                    :class="{'text-danger': states.translation_key === false}"
                    class="col-form-label col-lg-2"
                    for="translation_key"
                >{{ $t('dynamic_fields::fields.columns.translation_key').ucFirst() }}</label
                >
                <div class="col-lg-10">
                    <translation-input
                        v-model="model.translation_key"
                        :error="errors.translation_key"
                        :placeholder="$t('dynamic_fields::fields.placeholders.translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                        :state="states.translation_key"
                    ></translation-input>
                </div>
            </div>
            <div class="row mb-4">
                <label
                    :class="{'text-danger': states.placeholder === false}"
                    class="col-form-label col-lg-2"
                    for="placeholder"
                >{{ $t('dynamic_fields::fields.columns.placeholder').ucFirst() }}</label>
                <div class="col-lg-10">
                    <input
                        id="placeholder"
                        v-model="model.placeholder"
                        :class="{'border-danger': states.placeholder === false}"
                        :placeholder="$t('dynamic_fields::fields.placeholders.placeholder').ucFirst()"
                        class="form-control"
                        name="placeholder"
                        type="text"
                    />
                    <div :class="{'d-block': states.placeholder === false}" class="invalid-feedback">
                        {{ errors.placeholder }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <label
                    :class="{'text-danger': states.placeholder_translation_key === false}"
                    class="col-form-label col-lg-2"
                    for="placeholder_translation_key"
                >{{ $t('dynamic_fields::fields.columns.placeholder_translation_key').ucFirst() }}</label
                >
                <div class="col-lg-10">
                    <translation-input
                        v-model="model.placeholder_translation_key"
                        :error="errors.placeholder_translation_key"
                        :placeholder="$t('dynamic_fields::fields.placeholders.placeholder_translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                        :state="states.placeholder_translation_key"
                    ></translation-input>
                </div>
            </div>
            <div class="row mb-4">
                <label
                    :class="{'text-danger': states.unit === false}"
                    class="col-form-label col-lg-2"
                    for="unit"
                >{{ $t('dynamic_fields::fields.columns.unit').ucFirst() }}</label>
                <div class="col-lg-10">
                    <input
                        id="unit"
                        v-model="model.unit"
                        :class="{'border-danger': states.unit === false}"
                        :placeholder="$t('dynamic_fields::fields.placeholders.unit').ucFirst()"
                        class="form-control"
                        name="unit"
                        type="text"
                    />
                    <div :class="{'d-block': states.unit === false}" class="invalid-feedback">{{ errors.unit }}</div>
                </div>
            </div>
            <div class="row mb-4">
                <label
                    :class="{'text-danger': states.versionable === false}"
                    class="col-form-label col-lg-2"
                    for="versionable"
                >{{ $t('dynamic_fields::fields.columns.versionable').ucFirst() }}</label>
                <div class="col-lg-10">
                    <div class="form-check form-switch form-switch-md mb-3 ps-0">
                        <input
                            id="versionable"
                            v-model="model.versionable"
                            :class="{'border-danger': states.versionable === false}"
                            class="form-check-input ms-0"
                            name="versionable"
                            type="checkbox"
                        />
                    </div>
                    <div :class="{'d-block': states.versionable === false}" class="invalid-feedback">
                        {{ errors.versionable }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <label
                    :class="{'text-danger': states.required === false}"
                    class="col-form-label col-lg-2"
                    for="required"
                >{{ $t('dynamic_fields::fields.columns.required').ucFirst() }}</label>
                <div class="col-lg-10">
                    <div class="form-check form-switch form-switch-md mb-3 ps-0">
                        <input
                            id="required"
                            v-model="model.options.required"
                            :class="{'border-danger': states.required === false}"
                            class="form-check-input ms-0"
                            name="required"
                            type="checkbox"
                        />
                    </div>
                    <div :class="{'d-block': states.required === false}" class="invalid-feedback">{{
                            errors.required
                        }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <label
                    :class="{'text-danger': states.nullable === false}"
                    class="col-form-label col-lg-2"
                    for="nullable"
                >{{ $t('dynamic_fields::fields.columns.nullable').ucFirst() }}</label>
                <div class="col-lg-10">
                    <div class="form-check form-switch form-switch-md mb-3 ps-0">
                        <input
                            id="nullable"
                            v-model="model.options.nullable"
                            :class="{'border-danger': states.nullable === false}"
                            class="form-check-input ms-0"
                            name="nullable"
                            type="checkbox"
                        />
                    </div>
                    <div :class="{'d-block': states.nullable === false}" class="invalid-feedback">{{
                            errors.nullable
                        }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <label
                    :class="{'text-danger': states.display_as_table_column === false}"
                    class="col-form-label col-lg-2"
                    for="display_as_table_column"
                >{{ $t('dynamic_fields::fields.columns.display_as_table_column').ucFirst() }}</label>
                <div class="col-lg-10">
                    <div class="form-check form-switch form-switch-md mb-3 ps-0">
                        <input
                            id="display_as_table_column"
                            v-model="model.options.display_as_table_column"
                            :class="{'border-danger': states.display_as_table_column === false}"
                            class="form-check-input ms-0"
                            name="display_as_table_column"
                            type="checkbox"
                        />
                    </div>
                    <div :class="{'d-block': states.display_as_table_column === false}" class="invalid-feedback">
                        {{ errors.display_as_table_column }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <label
                    :class="{'text-danger': states.width_on_the_form === false}"
                    class="col-form-label col-lg-2"
                    for="width_on_the_form"
                >{{ $t('dynamic_fields::fields.columns.width_on_the_form').ucFirst() }} ({{model.options.width_on_the_form}}/12)</label>
                <div class="col-lg-10">
                    <input
                        id="width_on_the_form"
                        v-model="model.options.width_on_the_form"
                        class="w-100 form-range"
                        :class="{'border-danger': states.width_on_the_form === false}"
                        name="width_on_the_form"
                        type="range"
                        min="1"
                        max="12"
                        step="1"
                    />
                    <div :class="{'d-block': states.width_on_the_form === false}" class="invalid-feedback">
                        {{ errors.width_on_the_form }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <label
                    :class="{'text-danger': states.validation === false}"
                    class="col-form-label col-lg-2"
                    for="validation"
                >{{ $t('dynamic_fields::fields.columns.validation').ucFirst() }}</label>
                <div class="col-lg-10">
                    <select
                        id="validation"
                        v-model="model.options.validation"
                        :class="{'border-danger': states.validation === false}"
                        class="form-select"
                        name="validation"
                    >
                        <option :value="model.id ? '' : undefined">
                            {{ $t('dynamic_fields::fields.placeholders.validation').ucFirst() }}
                        </option>
                        <option v-for="(option, optionIndex) in validateTypes" :key="optionIndex" :value="option">
                            {{ $t('dynamic_fields::fields.validations.' + option).ucFirst() }}
                        </option>
                    </select>
                    <!--                    <input
                                            id="validation"
                                            v-model="model.options.validation"
                                            :placeholder="$t('dynamic_fields::fields.placeholders.validation').ucFirst()"
                                            class="form-control"
                                            :class="{'border-danger': states.validation === false}"
                                            name="validation"
                                            type="text"
                                        />-->
                    <div :class="{'d-block': states.validation === false}" class="invalid-feedback">{{
                            errors.validation
                        }}
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-10">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<script>
import TranslationInput from "../elements/translation-input.vue";
import {mapGetters} from "vuex";

export default {
    name: "additional-data-manager-form",

    components: {TranslationInput},

    emits: ['saved'],

    props: {
        field: {
            type: Object,
            required: true
        },

        ownerType: {
            type: [String, Object]
        }
    },

    computed: {
        ...mapGetters({
            'owners': 'field/owners',
            'loading': 'field/loading',
            'states': 'field/states',
            'errors': 'field/errors',
            'types': 'fieldType/all'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },

        owner: function () {
            if (!this.model.owner_type || !this.owners || !Object.keys(this.owners).length) {
                return
            }

            const type = Object.values(this.owners).find(own => own.model_name === this.model.owner_type)

            if (type) {
                return type.translation_key ? this.$t(type.translation_key).ucFirst() : type.name
            }

            return ''
        },

        type: function () {
            if (!this.model || !this.model.field_type_id || !this.types || !this.types.length) {
                return null;
            }

            return this.types.find(type => type.id === this.model.field_type_id)
        },

        inputType: function () {
            if (!this.type || !this.type.options) {
                return null
            }

            return this.type.options.type
        },

        selectOptions: function () {
            if (!this.model || !this.model.options || !this.model.options.options || !this.model.options.options.length) {
                return null
            }

            return this.model.options.options
        },

        hasOptionList: function () {
            if (!this.type) {
                return false;
            }

            return ['select', 'radio'].includes(this.type.options.type)
        },

        validateTypes: function () {
            return ['string', 'integer', 'date', 'boolean', 'file', 'image']
        },
    },

    data: function () {
        return {
            model: {},
            option: {},
            selectedOption: null,
            editOn: false
        }
    },

    methods: {
        edit: function () {
            this.option = JSON.parse(JSON.stringify(this.model.options.options.find(option => option.value === this.selectedOption)))
            this.editOn = true
        },

        remove: function () {
            const index = this.model.options.options.findIndex(option => option.value === this.selectedOption)
            this.model.options.options.splice(index, 1)
        },

        saveSelectOption: function () {
            const index = this.model.options.options.findIndex(option => option.value === this.selectedOption)
            this.model.options.options.splice(index, 1, this.option)
            this.editOn = false
            this.option = {}
        },

        addToSelectOptions: function () {
            if (!this.model.options.options) {
                this.model.options.options = [];
            }

            this.model.options.options.push(this.option)

            this.option = {}
        },

        getOwnerTypeValue: function () {
            return this.owners && this.owners.length
                ? this.owners.find(owner => this.$tc(owner.translation_key, 2).normalize('NFD').replace(/\p{Diacritic}/gu, '') === this.ownerType).model_name
                : null
        },

        setModel: function () {
            const model = JSON.parse(JSON.stringify(this.field))
            if ((Array.isArray(model.options) && !model.options.length) || !model.options) {
                model.options = {}
            }
            this.model = Object.assign({owner_type: this.getOwnerTypeValue()}, model);
        },

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            return this.$store.dispatch(`field/${method}`, this.model).then(saved => {
                this.model = JSON.parse(JSON.stringify(saved))
                this.$emit('saved')
                if (method === 'create') {
                    this.$router.push(this.$t('routes.' + '/master-data/additional-data/:id(\\d+)/:type?', {id: this.model.id, type: this.$route.params.type}).rmValidation())
                }
            })
        },
    },

    watch: {
        field: function () {
            this.setModel()
        },

        ownerType: function (value) {
            this.model.owner_type = value;
        },

        owners: function () {
            if (this.ownerType) {
                this.model.owner_type = this.owners.find(owner => this.$tc(owner.translation_key, 2).normalize('NFD').replace(/\p{Diacritic}/gu, '') === this.ownerType).model_name;
            }
        },
    },

    created() {
        this.setModel()
    }
}
</script>

<style scoped>

</style>
